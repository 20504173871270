import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import tmdb from "../utils/tmdb";
import mediaUtil from "../utils/media";
import StreamProvider from "./StreamProvider/StreamProvider";
import MediaPoster from "./MediaPoster/MediaPoster";
import MediaTrailer from "./MediaTrailer/MediaTrailer";
import MediaActions from "./MediaActions/MediaActions";
import MediaMetadata from "./MediaMetadata/MediaMetadata";
import Header from "../Header/Header";
import { Helmet } from "react-helmet-async";

function Media() {
  const { id } = useParams();
  const location = useLocation();
  const media_type = location.pathname.split("/")[1];
  const [media, setMedia] = useState({});
  const [videos, setVideos] = useState([]);
  const [watchProviders, setWatchProviders] = useState([]);

  useEffect(() => {
    tmdb.getMedia(media_type, id).then((data) => {
      const media = mediaUtil.formatData(data, media_type);
      setMedia(media);
    });
    tmdb.getMediaVideos(media_type, id).then((data) => {
      setVideos(data);
    });
    if (media_type === "tv") {
      tmdb.getTVStreamingProviders(id).then((data) => {
        const providers = data?.US?.flatrate;
        if (providers) {
          if (providers.length > 3) {
            setWatchProviders(providers.slice(0, 3));
          } else {
            setWatchProviders(providers);
          }
        }
      });
    } else {
      tmdb.getMovieStreamingProviders(id).then((data) => {
        const providers = data?.US?.flatrate;
        if (providers) {
          if (providers.length > 3) {
            setWatchProviders(providers.slice(0, 3));
          } else {
            setWatchProviders(providers);
          }
        }
      });
    }
  }, [id, media_type]);

  return (
    <>
      <Helmet>
        <title>{media.title}</title>
      </Helmet>
      <Header />
      <div className="media-page">
        <MediaPoster media={media} />
        <h1 className="media-title">{media.title}</h1>
        <MediaTrailer videos={videos} />
        <MediaActions media={media} />
        <MediaMetadata media={media} />
        {watchProviders && watchProviders.length > 0 ? (
          <StreamProvider providers={watchProviders} />
        ) : null}
      </div>
    </>
  );
}

export default Media;
