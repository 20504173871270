import { useState, useEffect } from "react";
import MediaListBlock from "../Media/media-list-block";
import Slider from "../components/slider";
import Header from "../Header/Header";
import tmdb from "../utils/tmdb";
import watchlist from "../utils/watchlist";
import "./dashboard.css";

function Dashboard() {
  const [mediaList, setMediaList] = useState({});
  const [watchlistMedia, setWatchlistMedia] = useState([]);

  useEffect(() => {
    tmdb.getTrendingMedia().then((data) => {
      setMediaList(data);
    });
    setWatchlistMedia(watchlist.getWatchlist());
  }, []);

  return (
    <div>
      <Header>
        <h1>Home</h1>
      </Header>
      {mediaList !== undefined && mediaList.length > 0 ? (
        <div className="media-slider">
          <h2 className="text-left">Trending Now</h2>
          <Slider>
            {mediaList.map((item) => (
              <MediaListBlock key={item.id} item={item} />
            ))}
          </Slider>
        </div>
      ) : (
        <></>
      )}
      {watchlistMedia !== undefined && watchlistMedia.length > 0 ? (
        <div className="media-slider">
          <h2 className="text-left">My Watchlist</h2>
          <Slider>
            {watchlistMedia.map((item) => (
              <MediaListBlock key={item.id} item={item} />
            ))}
          </Slider>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Dashboard;
