import "./MediaPoster.css";

function MediaPoster({ media }) {
  return (
    <>
      <div className="media-backdrop">
        <img src={media.backdrop_path} alt={media.title} />
      </div>
      <div className="media-poster">
        <img src={media.poster_path} alt={media.title} />
      </div>
    </>
  );
}

export default MediaPoster;
