import "./MediaMetadata.css";

function MediaMetadata({ media }) {
  return (
    <div className="media-metadata">
      <div className="media-metadata-item">
        <div className="value">{media.score}</div>
        <div className="label">Score</div>
      </div>
      <div className="media-metadata-item">
        <div className="value">{media.language}</div>
        <div className="label">Lang</div>
      </div>
      <div className="media-metadata-item">
        <div className="value">{media.year}</div>
        <div className="label">Year</div>
      </div>
    </div>
  );
}

export default MediaMetadata;
