import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import "./MediaTrailer.css";

function MediaTrailer({ videos }) {
  const [trailer, setTrailer] = useState(null);

  useEffect(() => {
    if (videos && videos.length > 0) {
      const trailer = videos.find((video) => video.type === "Trailer");
      if (trailer) {
        setTrailer(trailer);
      }
    }
  }, [videos]);

  return trailer ? (
    <>
      <Helmet>
        <meta
          property="og:video"
          content={`https://www.youtube.com/watch?v=${trailer.key}`}
        />
      </Helmet>
      <div className="media-trailer">
        <a
          className="btn trailer"
          target="_blank"
          rel="noreferrer"
          href={`https://www.youtube.com/watch?v=${trailer.key}`}
        >
          <img src="/assets/icons/play-button.svg" alt="play" />
          <span>Watch Trailer</span>
        </a>
      </div>
    </>
  ) : null;
}

export default MediaTrailer;
