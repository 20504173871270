import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import "./Header.css";

function Header({ children }) {
  const auth = useAuth();
  const { user } = auth;

  const location = useLocation();

  return (
    <header>
      <div>{children}</div>
      {user ? (
        <div>
          <Link to="/account">
            <img
              src={user.photoURL ?? "/assets/images/default-user-photo.png"}
              alt="user"
            />
          </Link>
        </div>
      ) : (
        location.pathname !== "/login" &&
        location.pathname !== "/signup" && (
          <div>
            <Link to="/login" className="login-link">
              Login
            </Link>
          </div>
        )
      )}
    </header>
  );
}

export default Header;
