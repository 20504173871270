import { useAuth } from "../Context/AuthContext";

function Account() {
  const auth = useAuth();

  return (
    <div>
      <h2>Account</h2>
      <button
        onClick={() => {
          auth.logout();
        }}
      >
        Log Out
      </button>
    </div>
  );
}

export default Account;
