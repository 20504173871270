import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Search from "./app/search/search";
import Dashboard from "./app/dashboard/dashboard";
import Media from "./app/Media/Media";
import Library from "./app/Library/Library";
import Login from "./app/Auth/Login/Login";
import SignUp from "./app/Auth/SignUp/SignUp";
import Nav from "./app/nav/nav";
import Account from "./app/Account/Account";
import { AuthProvider } from "./app/Context/AuthContext";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <AuthProvider>
      <HelmetProvider>
        <div className="App">
          <div className="app-wrapper">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route
                path="/search"
                element={
                  <Search query={searchQuery} setQuery={setSearchQuery} />
                }
              />
              <Route path="/tv/:id" element={<Media />} />
              <Route path="/movie/:id" element={<Media />} />
              <Route path="/library" element={<Library />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/account" element={<Account />} />
            </Routes>
          </div>
          <Nav />
        </div>
      </HelmetProvider>
    </AuthProvider>
  );
}

export default App;
