import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import Header from "../../Header/Header";
import "./Login.css";

function Login() {
  const auth = useAuth();
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();

  const { user } = auth;
  if (user) {
    navigate("/");
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await auth.login(emailRef.current.value, passwordRef.current.value);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="login-page">
      <Header>
        <h1>Login</h1>
      </Header>
      <form onSubmit={handleLogin} className="login-form">
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="Email address"
            ref={emailRef}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder="Password"
            ref={passwordRef}
          />
        </div>
        <button type="submit" className="btn login">
          LOGIN
        </button>
      </form>
      <Link to="/signup" className="register-link">
        Create an account
      </Link>
    </div>
  );
}

export default Login;
