import { useState, useEffect } from "react";
import watchlist from "../utils/watchlist";
import MediaListItem from "../Media/media-list-item";
import Header from "../Header/Header";

function Library() {
  const [mediaList, setMediaList] = useState([]);

  useEffect(() => {
    const data = watchlist.getWatchlist();
    setMediaList(data);
  }, []);

  return (
    <div>
      <Header>
        <h1>Your Library</h1>
      </Header>
      <div className="media_list">
        {mediaList.map((media, index) => (
          <MediaListItem key={index} item={media} />
        ))}
      </div>
    </div>
  );
}

export default Library;
