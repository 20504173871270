import { useState, useEffect } from "react";
import watchlist from "../../utils/watchlist";
import watched from "../../utils/watched";
import "./MediaActions.css";

function MediaActions({ media }) {
  const [inWatchlist, setInWatchlist] = useState(false);

  const shareURL = () => {
    const data = {
      files: [],
      text: `Have you seen "${media.title}" yet?`,
      url: `https://app.movieslater.com/${media.type}/${media.id}`,
      title: media.title,
    };
    if (navigator.canShare(data)) {
      navigator.share(data);
    }
  };

  const toggleWatchlist = () => {
    if (inWatchlist) {
      watchlist.removeFromWatchlist(media.id, media.type);
    } else {
      watchlist.addToWatchlist(
        media.id,
        media.type,
        media.title,
        media.poster_path,
        media.year,
        media.language
      );
    }
    setInWatchlist(!inWatchlist);
  };

  const markWatched = () => {
    watched.addToWatched(
      media.id,
      media.type,
      media.title,
      media.poster_path,
      media.year,
      media.language
    );
    toggleWatchlist();
  };

  useEffect(() => {
    setInWatchlist(watchlist.isInWatchlist(media.id, media.type));
  }, [media.id, media.type]);

  return (
    <div className="actions">
      {inWatchlist ? (
        <button className="btn btn-primary watched" onClick={markWatched}>
          Mark Watched
        </button>
      ) : (
        <button className="btn btn-primary watchlist" onClick={toggleWatchlist}>
          Add to watchlist
        </button>
      )}
      <button className="btn btn-primary recommend" onClick={shareURL}>
        Recommend
      </button>
    </div>
  );
}

export default MediaActions;
