import { Link } from "react-router-dom";
import "./nav.css";

function Nav() {
  return (
    <nav className="nav">
      <div className="nav__item home">
        <Link to="/">Home</Link>
      </div>
      <div className="nav__item search">
        <Link to="/search">Search</Link>
      </div>
      <div className="nav__item account">
        <Link to="/library">Your Library</Link>
      </div>
    </nav>
  );
}

export default Nav;
