import React from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase";
import watchlist from "../utils/watchlist";
import watched from "../utils/watched";

const AuthContext = React.createContext();

export function useAuth() {
  return React.useContext(AuthContext);
}

async function createUserDoc(user) {
  await setDoc(doc(db, "users", user.user.uid), {
    email: user.email,
    createdAt: new Date(),
    updatedAt: new Date(),
    watchlist: watchlist.getWatchlist() ?? [],
    watched: watched.getWatched() ?? [],
  });
}

export function AuthProvider({ children }) {
  const [user, setUser] = React.useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));

        // Create User Document
        if (!userDoc.exists()) {
          await createUserDoc(user);
          return;
        }

        // Set Watchlist
        if (userDoc.data().watchlist) {
          watchlist.setWatchlist(userDoc.data().watchlist);
        } else {
          watchlist.setWatchlist([]);
        }

        // Set Watched
        if (userDoc.data().watched) {
          watched.setWatched(userDoc.data().watched);
        } else {
          watched.setWatched([]);
        }
      }
    })();
  }, [user]);

  onAuthStateChanged(auth, async (user) => {
    setUser(user);
    if (user) {
      const userDoc = await getDoc(doc(db, "users", user.uid));

      // Set Watchlist
      if (userDoc.data().watchlist) {
        watchlist.setWatchlist(userDoc.data().watchlist);
      } else {
        watchlist.setWatchlist([]);
      }

      // Set Watched
      if (userDoc.data().watched) {
        watched.setWatched(userDoc.data().watched);
      } else {
        watched.setWatched([]);
      }
    }
  });

  const value = {
    user,
    login: async (email, password) =>
      await signInWithEmailAndPassword(auth, email, password)
        .then(async (user) => {
          watchlist.clearWatchlist();
          watched.clearWatched();
          navigate("/");
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/user-not-found":
              console.log("User not found");
              break;
            case "auth/wrong-password":
              console.log("Wrong password");
              break;
            default:
              console.log(error);
          }
        }),
    logout: async () =>
      await signOut(auth)
        .then(() => {
          navigate("/");
          watchlist.clearWatchlist();
          watched.clearWatched();
        })
        .catch((error) => {
          console.log(error);
        }),
    signUp: async (email, password, fullName) =>
      await createUserWithEmailAndPassword(auth, email, password)
        .then(async (user) => {
          // Set Display Name
          await auth.currentUser.updateProfile({
            displayName: fullName,
          });

          // Create User Document
          await createUserDoc(user);

          // Navigate to Home
          navigate("/");
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/email-already-in-use":
              console.log("Email already in use");
              break;
            case "auth/invalid-email":
              console.log("Invalid email");
              break;
            case "auth/weak-password":
              console.log("Weak password");
              break;
            default:
              console.log(error);
          }
        }),
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
