import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";

const watchlist = {
  addToWatchlist: async (id, type, title, poster, year, language) => {
    // Add to local storage
    const watchlist = JSON.parse(localStorage.getItem("watchlist")) || [];
    if (!watchlist.find((item) => item.id === id && item.type === type)) {
      const newWatchlist = [
        { id, type, title, poster, year, language },
        ...watchlist,
      ];
      localStorage.setItem("watchlist", JSON.stringify(newWatchlist));

      // Update firestore
      const user = auth.currentUser;
      if (user) {
        updateDoc(doc(db, "users", user.uid), {
          watchlist: newWatchlist,
          updatedAt: new Date(),
        });
      }
    }
  },
  removeFromWatchlist: (id, type) => {
    // Remove from local storage
    const watchlist = JSON.parse(localStorage.getItem("watchlist")) || [];
    const newWatchlist = watchlist.filter(
      (item) => item.id !== id || item.type !== type
    );
    localStorage.setItem("watchlist", JSON.stringify(newWatchlist));

    // Update firestore
    const user = auth.currentUser;
    if (user) {
      updateDoc(doc(db, "users", user.uid), {
        watchlist: newWatchlist,
        updatedAt: new Date(),
      });
    }
  },
  isInWatchlist: (id, type) => {
    // Check if in local storage
    const watchlist = JSON.parse(localStorage.getItem("watchlist")) || [];
    return watchlist.some((item) => item.id === id && item.type === type);
  },
  getWatchlist: () =>
    // Get local storage
    JSON.parse(localStorage.getItem("watchlist")) || [],
  setWatchlist: (watchlist) => {
    // Set local storage
    localStorage.setItem("watchlist", JSON.stringify(watchlist));
  },
  clearWatchlist: () => {
    // Clear local storage
    localStorage.removeItem("watchlist");
  },
};

export default watchlist;
