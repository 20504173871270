import { useState } from "react";
import SearchBar from "./search-bar";
import SearchResults from "./search-results";
import Header from "../Header/Header";
import "./search.css";

function Search({ query, setQuery }) {
  const [searchResults, setSearchResults] = useState([]);

  return (
    <>
      <Header>
        <h1>Search</h1>
      </Header>
      <div className="search">
        <SearchBar
          setResults={setSearchResults}
          query={query}
          setQuery={setQuery}
        />
        <SearchResults results={searchResults} />
      </div>
    </>
  );
}

export default Search;
