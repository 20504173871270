import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import Header from "../../Header/Header";
import "./SignUp.css";

function SignUp() {
  const auth = useAuth();
  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();

  const { user } = auth;
  if (user) {
    navigate("/");
  }

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      await auth.signUp(
        emailRef.current.value,
        passwordRef.current.value,
        nameRef.current.value
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="signup-page">
      <Header>
        <h1>Sign Up</h1>
      </Header>
      <form onSubmit={handleSignUp} className="signup-form">
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Full Name"
            ref={nameRef}
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="Email address"
            ref={emailRef}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder="Password"
            ref={passwordRef}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Sign Up
        </button>
      </form>
      <Link to="/login" className="register-link">
        Already have an account?
      </Link>
    </div>
  );
}

export default SignUp;
