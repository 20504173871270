import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";

const watched = {
  addToWatched: async (id, type, title, poster, year, language) => {
    // Add to local storage
    const watched = JSON.parse(localStorage.getItem("watched")) || [];
    if (!watched.find((item) => item.id === id && item.type === type)) {
      const newWatched = [
        { id, type, title, poster, year, language },
        ...watched,
      ];
      localStorage.setItem("watched", JSON.stringify(newWatched));

      // Update firestore
      const user = auth.currentUser;
      if (user) {
        updateDoc(doc(db, "users", user.uid), {
          watched: newWatched,
          updatedAt: new Date(),
        });
      }
    }
  },
  removeFromWatched: (id, type) => {
    // Remove from local storage
    const watched = JSON.parse(localStorage.getItem("watched")) || [];
    const newWatched = watched.filter(
      (item) => item.id !== id || item.type !== type
    );
    localStorage.setItem("watched", JSON.stringify(newWatched));

    // Update firestore
    const user = auth.currentUser;
    if (user) {
      updateDoc(doc(db, "users", user.uid), {
        watched: newWatched,
        updatedAt: new Date(),
      });
    }
  },
  isInWatched: (id, type) => {
    // Check if in local storage
    const watched = JSON.parse(localStorage.getItem("watched")) || [];
    return watched.some((item) => item.id === id && item.type === type);
  },
  getWatched: () =>
    // Get local storage
    JSON.parse(localStorage.getItem("watched")) || [],
  setWatched: (watched) => {
    // Set local storage
    localStorage.setItem("watched", JSON.stringify(watched));
  },
  clearWatched: () => {
    // Clear local storage
    localStorage.removeItem("watched");
  },
};

export default watched;
